import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styles from './blog-post.module.css'

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <div className={styles.container}>
      <Helmet>
        <title>{post.frontmatter.title}</title>
        <meta name="description" content={post.frontmatter.excerpt} />
      </Helmet>

      <div className={styles.innerContainer}>
        <a className={styles.nav} href="/">
          Read More...
        </a>
        <h1 className={styles.title}>{post.frontmatter.title}</h1>
        <p className={styles.subTitle}>
          {post.frontmatter.date} &#183; {post.timeToRead} min read
        </p>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </div>
  )
}

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
